import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import { Forecast, Social, Settings, PrivacyPolicy, NotFound, ErrorPage } from './components';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'forecast',
        element: <Forecast />,
        errorElement: <ErrorPage />
      },
      {
        path: 'social',
        element: <Social />,
        errorElement: <ErrorPage />
      },
      {
        path: 'settings',
        element: <Settings />,
        errorElement: <ErrorPage />
      },
      {
        path: 'privacypolicy',
        element: <PrivacyPolicy />,
        errorElement: <ErrorPage />
      },
      { path: '*', element: <NotFound /> }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
