import { Grid, Grow, Skeleton, Typography } from '@mui/material';

export const ForecastLoading = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grow in>
          <Typography>Getting forecast details...</Typography>
        </Grow>
      </Grid>
      {/* Conditionally applies the timeout prop to change the entry speed. */}
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 }}>
          {<Skeleton animation="wave" />}
        </Grow>
      </Grid>
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 2000 }}>
          {<Skeleton animation="wave" />}
        </Grow>
      </Grid>
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 3000 }}>
          {<Skeleton animation="wave" />}
        </Grow>
      </Grid>
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 4000 }}>
          {<Skeleton animation="wave" />}
        </Grow>
      </Grid>
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 5000 }}>
          {<Skeleton animation="wave" />}
        </Grow>
      </Grid>
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 6000 }}>
          {<Skeleton animation="wave" />}
        </Grow>
      </Grid>
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 7000 }}>
          {<Skeleton animation="wave" />}
        </Grow>
      </Grid>
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 8000 }}>
          {<Skeleton animation="wave" />}
        </Grow>
      </Grid>
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 9000 }}>
          {<Skeleton animation="wave" />}
        </Grow>
      </Grid>
      <Grid item xs={12}>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 10000 }}>
          {<Skeleton animation="wave" width="80%" />}
        </Grow>
      </Grid>
    </Grid>
  );
};

export default ForecastLoading;
