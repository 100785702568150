import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography, Grid } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import { Facebook } from '@mui/icons-material';
// import PullToRefresh from 'react-simple-pull-to-refresh';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { ApplicationContext } from '../App';
// import { ContentContainer, PostalCodeModal, EmbeddedTweet } from '../components';
// import { getUserSettings } from '../helpers/storageHelpers';
// import { TwitterResponse } from '../models/TwitterResponse';

export const Social = () => {
  const { currentForecast } = useContext(ApplicationContext);
  const state = currentForecast?.state.toLowerCase();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentForecast || !state) {
      navigate('/');
    }
  }, [state, currentForecast, navigate]);

  const urlHashTag = `%23${state}wx`;
  const hashTag = `#${state}wx`;
  // const [error, setError] = useState('');
  // const [postalCodeModalOpen, setPostalCodeModalOpen] = useState(!postalCode);
  // const [tweetIndex, setTweetIndex] = useState(0);
  // const [tweetChunk, setTweetChunk] = useState<string[]>([]);
  // const [tweetsLoading, setTweetsLoading] = useState(false);
  // const [moreTweetsLoading, setMoreTweetsLoading] = useState(false);

  // const getTweets = useCallback(async () => {
  //   setError('');
  //   doSetCurrentTwitterResponse(undefined);
  //   setTweetIndex(0);
  //   setTweetChunk([]);
  //   setTweetsLoading(true);
  //   toggleAppLoading();
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/Social/${postalCode}`);
  //     if (!response.ok) {
  //       setError(`Unable to get tweets for ${postalCode} right now.`);
  //     } else {
  //       const tweets: TwitterResponse = await response.json();
  //       doSetCurrentTwitterResponse(tweets);
  //       setTweetChunk(tweets.tweetIds.slice(0, 5));
  //       setTweetIndex(4);
  //     }
  //   } catch (e: any) {
  //     setError(`Unable to get tweets for ${postalCode} right now.`);
  //   } finally {
  //     toggleAppLoading();
  //   }
  // }, [doSetCurrentTwitterResponse, toggleAppLoading, postalCode]);

  // useEffect(() => {
  //   if (postalCode && !currentTwitterResponse) {
  //     getTweets();
  //   }

  //   if (postalCode && currentTwitterResponse) {
  //     setTweetsLoading(true);
  //     setTweetChunk(currentTwitterResponse.tweetIds.slice(0, 5));
  //     setTweetIndex(4);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   getTweets();
  // }, [getTweets, postalCode]);

  // const handlePull = async () => {
  //   if (!appLoading && !tweetsLoading) getTweets();
  // };

  // const handlePostalCodeModalClose = () => {
  //   setPostalCodeModalOpen(false);
  //   doSetPostalCode(postalCode);
  // };

  // const handleLoadMoreTweets = () => {
  //   setTimeout(() => {
  //     if (tweetChunk.length !== currentTwitterResponse?.tweetIds.length) {
  //       setMoreTweetsLoading(true);
  //     }
  //     const newTweetIds = currentTwitterResponse!.tweetIds.slice(0, tweetIndex + 5);
  //     setTweetChunk(newTweetIds);
  //     setTweetIndex(tweetIndex + 5);
  //   }, 1000);
  // };

  // const getContent = () => {
  //   if (error) {
  //     return (
  //       <Container sx={{ p: 3, minHeight: '70vh' }}>
  //         <ContentContainer>
  //           <Typography>{error}</Typography>
  //         </ContentContainer>
  //       </Container>
  //     );
  //   }

  //   return (
  //     <>
  //       {tweetChunk && !!tweetChunk.length && (
  //         <InfiniteScroll
  //           dataLength={tweetChunk?.length}
  //           next={handleLoadMoreTweets}
  //           hasMore={!moreTweetsLoading && tweetIndex <= currentTwitterResponse!.tweetIds.length}
  //           scrollThreshold={1}
  //           endMessage={
  //             <>
  //               {!moreTweetsLoading &&
  //                 tweetChunk.length === currentTwitterResponse?.tweetIds.length && (
  //                   <Typography variant="body1" align="center">
  //                     No more tweets for now...
  //                   </Typography>
  //                 )}
  //             </>
  //           }
  //           loader={
  //             <Container maxWidth="xs" sx={{ textAlign: 'center', mt: 2 }}>
  //               <CircularProgress color="primary" size={25} />
  //             </Container>
  //           }>
  //           {tweetChunk.map((x: string, index: number) => {
  //             return (
  //               <EmbeddedTweet
  //                 align="center"
  //                 isInitialLoad={index === 0}
  //                 onInitialLoad={() => setTweetsLoading(false)}
  //                 theme={getUserSettings().lightMode}
  //                 tweetId={x}
  //                 onMoreTweetsLoaded={() => setMoreTweetsLoading(false)}
  //                 key={`${x}-${index}`}
  //               />
  //             );
  //           })}
  //         </InfiniteScroll>
  //       )}
  //     </>
  //   );
  // };

  if (!currentForecast || !state) {
    return null;
  }
  return (
    <Container maxWidth="xs" sx={{ textAlign: 'center', mt: '25vh' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() =>
              window.open(`https://twitter.com/search?q=${urlHashTag}&f=live`, '_blank')
            }>
            <Grid container spacing="4" justifyContent="center" textAlign="center">
              <Grid item xs={12}>
                <XIcon />
              </Grid>
              <Grid item>
                <Typography>{hashTag}</Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => window.open(`https://facebook.com/hashtag/${state}wx`, '_blank')}>
            <Grid container spacing="4" justifyContent="center" textAlign="center">
              <Grid item xs={12}>
                <Facebook />
              </Grid>
              <Grid item>
                <Typography>{hashTag}</Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Social;
