import * as React from 'react';
import { Box, Paper } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { WbSunny, Settings, Tag } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === '/forecast' && value !== 0) {
    setValue(0);
  }

  if (location.pathname === '/social' && value !== 1) {
    setValue(1);
  }

  if (location.pathname === '/settings' && value !== 2) {
    setValue(2);
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1000,
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.default',
        width: '100%'
      }}>
      <Paper elevation={3}>
        <Box sx={{ py: 1, bgcolor: 'background.default' }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}>
            <BottomNavigationAction
              label="Forecast"
              icon={<WbSunny />}
              onClick={() => navigate('forecast')}
            />
            <BottomNavigationAction
              label="Social"
              icon={<Tag />}
              onClick={() => navigate('social')}
            />
            <BottomNavigationAction
              label="Settings"
              icon={<Settings />}
              onClick={() => navigate('settings')}
            />
          </BottomNavigation>
        </Box>
      </Paper>
    </Box>
  );
}
