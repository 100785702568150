import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';

export interface ContentContainerProps {
  children: React.ReactNode;
}

export const ContentContainer = (props: ContentContainerProps) => {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up('lg'));

  return <Box sx={{ px: large ? '24vw' : undefined }}>{props.children}</Box>;
};

export default ContentContainer;
