import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import {
  CssBaseline,
  Container,
  AppBar,
  Paper,
  useScrollTrigger,
  Slide,
  Alert
} from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { BottomNavigation } from './components';
import { getUserSettings, setUserSettings } from './helpers/storageHelpers';
import { useServiceWorker } from './useServiceWorker';
import { FreeForecast } from './models/FreeForecast';
import { TwitterResponse } from './models/TwitterResponse';

import './App.css';

export const ApplicationContext = React.createContext({
  toggleColorMode: () => {},
  toggleAppLoading: () => {},
  toggleFollowMe: () => {},
  doSetCurrentForecast: (forecast: FreeForecast | undefined) => {},
  doSetCurrentTwitterResponse: (twitterResponse: TwitterResponse | undefined) => {},
  doSetPostalCode: (postalCode: string) => {},
  appLoading: false,
  currentForecast: undefined as FreeForecast | undefined,
  currentTwitterResponse: undefined as TwitterResponse | undefined,
  followMe: false,
  postalCode: ''
});

export const App = () => {
  const [mode, setMode] = React.useState<'light' | 'dark'>(getUserSettings().lightMode);
  const [followMe, setFollowMe] = React.useState<boolean>(getUserSettings().followMe);
  const [postalCode, setPostalCode] = React.useState<string>(getUserSettings().postalCode);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentForecast, setCurrentForecast] = React.useState<FreeForecast | undefined>(undefined);
  const [currentTwitterResponse, setCurrentTwitterResponse] = React.useState<
    TwitterResponse | undefined
  >(undefined);
  const [showUpdateButton, setShowUpdateButton] = React.useState<boolean>(false);
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  const setPostalCodeStateAndSetting = (value: string) => {
    const userSettings = getUserSettings();

    if (userSettings.postalCode !== value) {
      userSettings.postalCode = value;
      setUserSettings(userSettings);
      setPostalCode(value);
    }
  };

  const setFollowMeStateAndSetting = () => {
    setFollowMe((prevFollowMe) => {
      const userSettings = getUserSettings();
      userSettings.followMe = !prevFollowMe;
      setUserSettings(userSettings);
      return !prevFollowMe;
    });
  };

  const appToggles = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          let userSettings = getUserSettings();
          userSettings.lightMode = newMode;
          setUserSettings(userSettings);
          return newMode;
        });
      },
      toggleAppLoading: () => {
        setLoading((prevLoading) => {
          return !prevLoading;
        });
      },
      doSetCurrentForecast: (forecast: FreeForecast | undefined) => {
        setCurrentForecast(forecast);
      },
      doSetCurrentTwitterResponse: (twitterResponse: TwitterResponse | undefined) => {
        setCurrentTwitterResponse(twitterResponse);
      },
      doSetPostalCode: (value: string) => {
        setPostalCodeStateAndSetting(value);
      },
      toggleFollowMe: () => setFollowMeStateAndSetting()
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: '#87CEEB'
          },
          secondary: {
            main: '#818181'
          },
          mode
        }
      }),
    [mode]
  );

  theme = responsiveFontSizes(theme);

  const navigate = useNavigate();
  const location = useLocation();
  const scrollTrigger = useScrollTrigger();

  useEffect(() => {
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    }
  }, []);

  useEffect(() => {
    if (!location?.pathname || location?.pathname === '/' || location?.pathname === '') {
      navigate('forecast');
    }
    if (location.pathname) {
      ReactGA.send({
        hitType: 'pageview',
        page_location: location.pathname,
        page_title: location.pathname
      });
    }
  }, [location?.pathname, navigate]);

  useEffect(() => {
    if (showReload && waitingWorker) {
      setShowUpdateButton(true);
    }
  }, [waitingWorker, showReload]);

  const handleUpdateClick = () => {
    reloadPage();
    setShowUpdateButton(false);
  };

  return (
    <ApplicationContext.Provider
      value={{
        ...appToggles,
        appLoading: loading,
        currentForecast,
        currentTwitterResponse,
        followMe,
        postalCode
      }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Slide appear={false} direction="down" in={!scrollTrigger}>
          <AppBar>
            <Paper
              sx={{
                textAlign: 'center',
                bgcolor: 'background.default',
                borderRadius: 0,
                boxShadow: 'none'
              }}
              elevation={1}>
              <img
                src="logo192.png"
                style={{
                  height: '3rem',
                  cursor: 'pointer',
                  paddingTop: '0.50rem',
                  paddingBottom: '0rem'
                }}
                onClick={() => navigate('')}
                alt="WxYapper Logo"
              />
            </Paper>
          </AppBar>
        </Slide>
        <Box
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
            borderRadius: 0,
            mb: 10
          }}>
          {showUpdateButton && (
            <Container maxWidth="xs" sx={{ textAlign: 'center', mt: 8 }}>
              <Alert severity="info" onClick={handleUpdateClick} sx={{ cursor: 'pointer' }}>
                A new version is available! Tap to update.
              </Alert>
            </Container>
          )}
          <Outlet />
        </Box>
        <BottomNavigation />
      </ThemeProvider>
    </ApplicationContext.Provider>
  );
};

export default App;
