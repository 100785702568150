import { useState, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  Box,
  Typography
} from '@mui/material';
import { Form } from 'react-router-dom';
import { ApplicationContext } from '../App';
import { SearchBox } from '@mapbox/search-js-react';

export interface PostalCodeModalProps {
  postalCode: string;
  open: boolean;
  handleClose: () => void;
  onSubmit?: () => void;
}

export const PostalCodeModal = (props: PostalCodeModalProps) => {
  const { doSetCurrentForecast, doSetPostalCode, postalCode } = useContext(ApplicationContext);
  const [postalCodeValue, setPostalCodeValue] = useState<string>(postalCode);
  const [addressSearchError, setAddressSearchError] = useState('');
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('md'));
  const style = {
    paddingTop: 6,
    '& .MuiDialog-container': {
      alignItems: small ? 'flex-start' : 'center'
    },
    zIndex: 1000
  };

  const handleChange = (e: any) => {
    const regex = /^\d{0,5}$/;
    if (regex.test(e.target.value)) {
      setPostalCodeValue(e.target.value);
    }
  };
  const handleSubmit = () => {
    doSetPostalCode(postalCodeValue);
    doSetCurrentForecast(undefined);
    if (props.onSubmit) {
      props.onSubmit();
    }

    props.handleClose();
  };

  const onClose = (e: any, r: any) => {
    if (r === 'backdropClick') {
      return;
    }
    props.handleClose();
  };

  const mode = theme.palette.mode;

  return (
    <Dialog open={props.open} onClose={onClose} sx={style} fullScreen>
      <Form method="get">
        <DialogTitle>Postal Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To get a local forecast, enter a US Postal Code here.
          </DialogContentText>
          <TextField
            id="postalcode"
            label="Postal Code"
            type="tel"
            autoFocus
            margin="dense"
            variant="standard"
            value={postalCodeValue}
            onChange={handleChange}
          />
          <Box sx={{ zIndex: 1001, py: 2 }}>
            <Typography variant="caption">Search for Address:</Typography>
            <SearchBox
              accessToken={`${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
              value=""
              onRetrieve={(res) => {
                const postalCode = res?.features[0]?.properties?.context?.postcode?.name;
                setAddressSearchError('');
                if (postalCode) {
                  setPostalCodeValue(postalCode);
                } else {
                  setAddressSearchError(
                    'Selected location does not have a zipcode, select a different location.'
                  );
                }
              }}
              options={{
                country: 'US',
                language: 'en',
                limit: 10
              }}
              theme={{
                variables: {
                  border: `solid 0.1rem ${theme.palette.primary.main}`,
                  colorText: theme.palette.text.primary,
                  colorBackground: theme.palette.background.default,
                  colorBackgroundHover:
                    mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
                  colorBackgroundActive:
                    mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[300]
                }
              }}
            />
            <Typography variant="caption" color={theme.palette.error.main}>
              {addressSearchError}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} disabled={!postalCodeValue}>
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit} disabled={!postalCodeValue}>
            Submit
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default PostalCodeModal;
