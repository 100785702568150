import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
  Container,
  CircularProgress,
  Grid,
  Divider,
  Button,
  Stack
} from '@mui/material';
import { WarningRounded } from '@mui/icons-material';
import { format, add } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCountUp } from 'react-countup';
import { ApplicationContext } from '../App';
import { FreeForecast, GridPointPeriod } from '../models/FreeForecast';
import {
  PostalCodeModal,
  ContentContainer,
  CurrentObsModal,
  ForecastLoading,
  AlertsModal
} from '../components';
import { getTemperatureValue, getTemperatureUnitDisplay } from '../helpers/unitConverter';
import { useServiceWorker } from '../useServiceWorker';

// Import Swiper styles
import 'swiper/css';
import PullToRefresh from 'react-simple-pull-to-refresh';

export const Forecast = () => {
  const {
    toggleAppLoading,
    appLoading,
    currentForecast,
    doSetCurrentForecast,
    postalCode,
    followMe,
    doSetPostalCode
  } = useContext(ApplicationContext);
  const [postalCodeModalOpen, setPostalCodeModalOpen] = useState(!postalCode);
  const [error, setError] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [swiper, setSwiper] = useState<any>(null);
  const [countUpStartValue, setCountUpStartValue] = useState(0);
  const [currentObsModalOpen, setCurrentObsModalOpen] = useState(false);
  const [alertsModalOpen, setAlertsModalOpen] = useState(false);
  const { showReload } = useServiceWorker();

  const countUpRef = React.useRef(null);
  var temp = currentForecast?.currentWeather?.temperature?.value;

  const { update, start } = useCountUp({
    ref: countUpRef,
    start: countUpStartValue,
    duration: 3,
    end: temp !== null && temp !== undefined ? getTemperatureValue(temp) : 0,
    delay: 0,

    onEnd: () =>
      setCountUpStartValue(temp !== null && temp !== undefined ? getTemperatureValue(temp) : 0)
  });

  const getZipcode = useCallback(async () => {
    const getCoords = async () => {
      const pos: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      return {
        long: pos.coords.longitude,
        lat: pos.coords.latitude
      };
    };

    const getGeocode = async () => {
      try {
        const pos = await getCoords();
        if (pos) {
          const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${pos.long},${pos.lat}.json?types=postcode&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
          );
          if (!response.ok) {
            console.log('Unable to get postal code from mapbox.');
          } else {
            const geoCodeResponse: any = await response.json();
            if (geoCodeResponse?.features[0]?.text) {
              return geoCodeResponse?.features[0]?.text;
            }
          }
        } else {
          console.log('coordinates not returned.');
        }
      } catch (e: any) {
        console.log('some error');
      }
    };

    if (followMe) {
      var geoCode = await getGeocode();
      if (geoCode !== undefined) {
        doSetPostalCode(geoCode as string);
      }
      return geoCode;
    }
  }, [followMe, doSetPostalCode]);

  const getForecast = useCallback(async () => {
    setError('');
    toggleAppLoading();
    const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));
    let zipcodeToUse = postalCode;
    try {
      if (followMe) {
        zipcodeToUse = await getZipcode();
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/WeatherForecast/${zipcodeToUse}`
      );
      if (!response.ok) {
        setError(
          `Unable to get forecast for ${zipcodeToUse}. Try a different postal code and make sure you are connected to the internet.`
        );
      } else {
        const freeForecast: FreeForecast = await response.json();

        doSetCurrentForecast(freeForecast);

        if (freeForecast.forecastLoading) {
          await timer(3000);
          getForecast();
        }
      }
    } catch (e: any) {
      setError(
        `Unable to get forecast for ${zipcodeToUse}. Try a different postal code and make sure you are connected to the internet.`
      );
    } finally {
      toggleAppLoading();
    }
  }, [getZipcode, doSetCurrentForecast, postalCode, toggleAppLoading, followMe]);

  const currentWeatherValid = useCallback(() => {
    if (currentForecast?.currentWeather?.temperature?.value) {
      const now = Date.now();
      const fourHoursAgo = add(now, { hours: -4 });
      const obsTime = currentForecast?.currentWeather?.timestamp
        ? new Date(currentForecast?.currentWeather?.timestamp)
        : undefined;
      if (obsTime && obsTime > fourHoursAgo) {
        return true;
      }
    }

    return false;
  }, [
    currentForecast?.currentWeather?.temperature?.value,
    currentForecast?.currentWeather?.timestamp
  ]);

  useEffect(() => {
    if (postalCode && !currentForecast) {
      getForecast();
    }
  }, [currentForecast, getForecast, postalCode]);

  useEffect(() => {
    var temp = currentForecast?.currentWeather?.temperature?.value;
    if (temp !== null && temp !== undefined) {
      update(getTemperatureValue(temp));
    }
  }, [update, currentForecast?.currentWeather?.temperature?.value]);

  useEffect(() => {
    start();
    var temp = currentForecast?.currentWeather?.temperature?.value;
    if (temp !== null && temp !== undefined) {
      update(getTemperatureValue(temp));
    }
  }, [update, tabIndex, currentForecast?.currentWeather?.temperature?.value, start]);

  const handlePostalCodeModalClose = () => {
    setPostalCodeModalOpen(false);
  };

  const handleCurrentObsModalClose = () => {
    setCurrentObsModalOpen(false);
  };

  const handleCurrentObsClick = () => {
    setCurrentObsModalOpen(true);
  };

  const handleAlertsModalClose = () => {
    setAlertsModalOpen(false);
  };

  const handleAlertsClick = () => {
    setAlertsModalOpen(true);
  };

  const onSlideChange = (thisSwiper: any) => {
    setTabIndex(thisSwiper.activeIndex);
    window.scrollTo(0, 0);
  };

  const onTabClick = (_event: React.SyntheticEvent, newValue: number) => {
    if (swiper) {
      swiper.slideTo(newValue);
    }
  };

  const handlePull = async () => {
    if (!appLoading) getForecast();
  };

  const getDailyForecastsInOrder = () => {
    const periods: (GridPointPeriod | undefined)[] = [];
    if (currentForecast?.dailyForecast?.periods?.length) {
      currentForecast.dailyForecast.periods.forEach((x: GridPointPeriod, index) => {
        if (index === 0 && !x.isDaytime) {
          periods.push(undefined);
          periods.push(x);
        } else {
          periods.push(x);
        }
      });
    }

    return periods;
  };

  const getContent = () => {
    if (error) {
      return (
        <Container sx={{ p: 3, minHeight: '70vh' }}>
          <ContentContainer>
            <Typography>{error}</Typography>
          </ContentContainer>
        </Container>
      );
    }

    var temp = currentForecast?.currentWeather?.temperature?.value;

    var dailyForecast = getDailyForecastsInOrder();

    const hasAlerts = !!currentForecast?.activeAlerts?.alertNotifications?.length;

    return (
      <Swiper onSlideChange={onSlideChange} onSwiper={setSwiper}>
        <SwiperSlide>
          {!appLoading && (
            <Typography
              align="center"
              variant="body1"
              fontSize={12}
              color="secondary"
              sx={{ paddingTop: 1 }}>
              Pull to Refresh
            </Typography>
          )}
          <Box sx={{ p: 3, pt: hasAlerts ? 0 : 3, minHeight: '70vh' }}>
            <ContentContainer>
              {currentForecast && tabIndex === 0 && (
                <Grid container justifyContent="space-between" alignItems="flex-end">
                  <Grid container justifyContent="space-between" alignItems="flex-end">
                    <Grid item xs={12}>
                      {hasAlerts && (
                        <Grid container justifyContent="center">
                          <Button
                            onClick={handleAlertsClick}
                            variant="text"
                            sx={{
                              px: 0.5,
                              borderWidth: 2,
                              textTransform: 'none',
                              borderColor: 'red'
                            }}>
                            <Grid container>
                              {currentForecast?.activeAlerts?.alertNotifications.map((x) => {
                                return (
                                  <Grid item key={x} xs={12}>
                                    <Stack direction="row" gap={0.5} alignItems="center">
                                      <WarningRounded sx={{ fontSize: 16 }} />
                                      <Typography variant="body1" color="primary" fontSize={12}>
                                        {x}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center" alignItems="flex-end">
                        <Button
                          onClick={handleCurrentObsClick}
                          variant="outlined"
                          sx={{ px: 0.5, borderWidth: 2, textTransform: 'none' }}>
                          {temp !== null && temp !== undefined && (
                            <Grid item xs={5}>
                              <Grid container justifyContent="space-between" alignItems="flex-end">
                                <Grid item xs={12}>
                                  <Grid container justifyContent="center" alignItems="flex-end">
                                    {currentWeatherValid() ? (
                                      <Typography
                                        variant="h3"
                                        color="text.primary"
                                        component="span"
                                        ref={countUpRef}></Typography>
                                    ) : (
                                      <Typography
                                        variant="h3"
                                        color="text.primary"
                                        component="span"
                                        ref={countUpRef}></Typography>
                                    )}
                                    <Typography variant="h3" component="span" color="text.primary">
                                      {getTemperatureUnitDisplay()}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container justifyContent="center" alignItems="flex-end">
                                    <Typography variant="caption" color="secondary" fontSize={10}>
                                      {currentWeatherValid()
                                        ? `${format(
                                            new Date(currentForecast.currentWeather.timestamp!),
                                            'M/dd/yyyy'
                                          )}`
                                        : 'Not a recent observation.'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {currentWeatherValid() && (
                                  <Grid item xs={12}>
                                    <Grid container justifyContent="center" alignItems="flex-end">
                                      <Typography variant="caption" color="secondary" fontSize={10}>
                                        {`${format(
                                          new Date(currentForecast.currentWeather.timestamp!),
                                          'h:mm a'
                                        )}`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          )}
                          {dailyForecast?.length && (
                            <Grid container justifyContent="space-between" alignItems="flex-end">
                              <Grid item xs={4}>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="flex-end">
                                  {dailyForecast[0] && (
                                    <>
                                      <Grid item xs={12}>
                                        <Typography variant="body1" color="secondary" fontSize={10}>
                                          {dailyForecast[0].name}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography variant="body2" color="text.primary">
                                          {dailyForecast[0].temperature}
                                          {getTemperatureUnitDisplay()}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Box sx={{ my: 0.5 }}>
                                          <Divider variant="middle" sx={{ borderWidth: 1.5 }} />
                                        </Box>
                                      </Grid>
                                    </>
                                  )}
                                  <Grid item xs={12}>
                                    {!dailyForecast[0] && (
                                      <Typography variant="body1" color="secondary" fontSize={10}>
                                        {dailyForecast[1]!.name}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="body2" color="text.primary">
                                      {dailyForecast[1]!.temperature}
                                      {getTemperatureUnitDisplay()}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {dailyForecast.map((x, index) => {
                                if (index >= 2 && index % 2 === 0 && index <= 4) {
                                  return (
                                    <Grid item xs={4} key={index}>
                                      <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="flex-end">
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="body1"
                                            color="secondary"
                                            fontSize={10}>
                                            {dailyForecast[index]!.name}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Typography variant="body2" color="text.primary">
                                            {dailyForecast[index]!.temperature}
                                            {getTemperatureUnitDisplay()}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Box sx={{ my: 0.5 }}>
                                            <Divider variant="middle" sx={{ borderWidth: 1.5 }} />
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Typography variant="body2" color="text.primary">
                                            {dailyForecast[index + 1]!.temperature}
                                            {getTemperatureUnitDisplay()}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                }
                                return null;
                              })}
                            </Grid>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ my: 2 }}>
                        <Divider variant="middle" />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {!currentForecast.forecastLoading && currentForecast.forecast && (
                        <>
                          <Typography variant="body1" color="secondary">
                            {`Forecast Issued for:`}
                          </Typography>
                          <Typography variant="body1" color="secondary">
                            {`${currentForecast.city}, ${currentForecast.state} ${currentForecast.postalCode}`}
                          </Typography>
                          <Typography variant="body2" color="secondary">
                            {`${format(new Date(currentForecast!.issued), 'EEEE, MMMM do, yyyy')}`}
                          </Typography>
                          <Typography variant="body2" color="secondary">
                            {`${format(new Date(currentForecast!.issued), 'h:mm a')}`}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  {!currentForecast.forecastLoading && currentForecast.forecast ? (
                    <Typography variant="h6" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
                      {currentForecast.forecast}
                    </Typography>
                  ) : (
                    <ForecastLoading />
                  )}
                </Grid>
              )}
            </ContentContainer>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box sx={{ p: 3, minHeight: '70vh' }}>
            <ContentContainer>
              <>
                {currentForecast && tabIndex === 1 && (
                  <>
                    {!currentForecast.forecastLoading && currentForecast.afd ? (
                      <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                        {currentForecast.afd}
                      </Typography>
                    ) : (
                      <ForecastLoading />
                    )}
                  </>
                )}
              </>
            </ContentContainer>
          </Box>
        </SwiperSlide>
      </Swiper>
    );
  };

  return (
    <>
      <AppBar sx={{ mt: showReload ? 0 : 8 }} position="sticky" elevation={0}>
        <Toolbar sx={{ width: '100%', bgcolor: 'background.default' }}>
          <Box sx={{ width: '100%' }}>
            <ContentContainer>
              <Tabs
                sx={{ bgcolor: 'background.default' }}
                value={tabIndex}
                variant="fullWidth"
                onChange={onTabClick}>
                <Tab label="Summary" />
                <Tab label="Discussion" />
              </Tabs>
            </ContentContainer>
          </Box>
        </Toolbar>
      </AppBar>
      {appLoading && (
        <Container maxWidth="xs" sx={{ textAlign: 'center', mt: 2 }}>
          <CircularProgress color="primary" size={25} />
        </Container>
      )}
      <PullToRefresh
        onRefresh={handlePull}
        className="pulltorefresh"
        refreshingContent={<></>}
        pullingContent={
          <Container maxWidth="xs" sx={{ textAlign: 'center', mt: 2 }}>
            <CircularProgress color="secondary" size={25} />
          </Container>
        }>
        {getContent()}
      </PullToRefresh>
      <PostalCodeModal
        open={postalCodeModalOpen}
        postalCode={postalCode}
        handleClose={handlePostalCodeModalClose}
      />
      <CurrentObsModal open={currentObsModalOpen} handleClose={handleCurrentObsModalClose} />
      <AlertsModal open={alertsModalOpen} handleClose={handleAlertsModalClose} />
    </>
  );
};

export default Forecast;
