import React from 'react';
import { Typography } from '@mui/material';

export const NotFound = () => {
  return (
    <Typography variant="h4" color="text.primary">
      Ope...Nothing here.
    </Typography>
  );
};

export default NotFound;
