import React from 'react';
import { Typography } from '@mui/material';

export const ErrorPage = () => {
  return (
    <Typography variant="h4" color="text.primary">
      Ope...Something went wrong.
    </Typography>
  );
};

export default ErrorPage;
