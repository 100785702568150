import React, { useState, useContext } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LightModeToggle, PostalCodeModal, ContentContainer, FollowMeToggle } from './';
import version from '../version.json';
import { useServiceWorker } from '../useServiceWorker';
import { ApplicationContext } from '../App';

export const Settings = () => {
  const { postalCode, followMe } = useContext(ApplicationContext);
  const [postalCodeModalOpen, setPostalCodeModalOpen] = useState(false);
  const navigate = useNavigate();
  const { showReload } = useServiceWorker();

  const handlePostalCodeModalUpdateClick = () => {
    setPostalCodeModalOpen(true);
  };
  const handlePostalCodeModalClose = () => {
    setPostalCodeModalOpen(false);
  };
  return (
    <ContentContainer>
      <Box sx={{ mt: showReload ? 1 : 8, minHeight: '70vh' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Light Mode</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LightModeToggle />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Set Postal Code</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FollowMeToggle />
            <Typography sx={{ px: 1 }}>
              {postalCode ? postalCode : 'Set Postal Code Now'}{' '}
              <Button
                size="small"
                onClick={handlePostalCodeModalUpdateClick}
                variant="contained"
                disabled={followMe}>
                Update
              </Button>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {`App Version: ${
                process.env.NODE_ENV === 'production' ? version.currentVersion : 'LOCAL DEV'
              }`}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Privacy Policy</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Button onClick={() => navigate('/privacypolicy')}>Go to privacy policy</Button>
          </AccordionDetails>
        </Accordion>
        <PostalCodeModal
          open={postalCodeModalOpen}
          handleClose={handlePostalCodeModalClose}
          postalCode={postalCode}
        />
      </Box>
    </ContentContainer>
  );
};

export default Settings;
