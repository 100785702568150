import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ApplicationContext } from '../App';

export const LightModeToggle = () => {
  const theme = useTheme();
  const { toggleColorMode } = React.useContext(ApplicationContext);
  return (
    <>
      {theme.palette.mode}
      <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </>
  );
};

export default LightModeToggle;
