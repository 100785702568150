export const getTemperatureDisplay = (valueC: number): string => {
  const f = valueC * 1.8 + 32;
  return `${Math.round(f)}\u00B0F`;
};

export const getTemperatureValue = (valueC: number): number => {
  const f = valueC * 1.8 + 32;
  return Math.round(f);
};

export const getTemperatureUnitDisplay = (): string => {
  return `\u00B0F`;
};

export const getWindDirection = (valueDeg: number) => {
  if (valueDeg > 348.75) return 'N';
  if (valueDeg <= 11.25) return 'N';
  if (valueDeg > 11.25 && valueDeg <= 33.75) return 'NNE';
  if (valueDeg > 33.75 && valueDeg <= 56.25) return 'NE';
  if (valueDeg > 56.25 && valueDeg <= 78.75) return 'ENE';
  if (valueDeg > 78.75 && valueDeg <= 101.25) return 'E';
  if (valueDeg > 101.25 && valueDeg <= 123.75) return 'ESE';
  if (valueDeg > 123.75 && valueDeg <= 146.25) return 'SE';
  if (valueDeg > 146.25 && valueDeg <= 168.75) return 'SSE';
  if (valueDeg > 168.75 && valueDeg <= 191.25) return 'S';
  if (valueDeg > 191.25 && valueDeg <= 213.75) return 'SSW';
  if (valueDeg > 213.75 && valueDeg <= 236.25) return 'SW';
  if (valueDeg > 236.25 && valueDeg <= 258.75) return 'WSW';
  if (valueDeg > 258.75 && valueDeg <= 281.25) return 'W';
  if (valueDeg > 281.25 && valueDeg <= 303.75) return 'WNW';
  if (valueDeg > 303.75 && valueDeg <= 326.25) return 'NW';
  if (valueDeg > 326.25 && valueDeg <= 348.75) return 'NNW';
};
