import { UserSettings } from '../models/UserSettings';

export const getUserSettings = (): UserSettings => {
  const userSettingsString = localStorage.getItem('userSettings');
  if (!userSettingsString) {
    const newUserSettings: UserSettings = { lightMode: 'dark', postalCode: '', followMe: false };
    setUserSettings(newUserSettings);
    return newUserSettings;
  }

  return JSON.parse(userSettingsString);
};

export const setUserSettings = (userSettings: UserSettings): UserSettings => {
  localStorage.setItem('userSettings', JSON.stringify(userSettings));

  return userSettings;
};
