import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  Grid,
  Box,
  Divider
} from '@mui/material';
import { formatDistance } from 'date-fns';
import { ApplicationContext } from '../App';

export interface AlertsModalProps {
  open: boolean;
  handleClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const AlertsModal = (props: AlertsModalProps) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('md'));
  const { currentForecast } = useContext(ApplicationContext);
  const style = {
    p: 4,
    '& .MuiDialog-container': {
      alignItems: small ? 'flex-start' : 'center'
    }
  };

  return (
    <Dialog fullScreen={small} open={props.open} onClose={props.handleClose} sx={style}>
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h4">Alerts</Typography>
          </Grid>
          {currentForecast?.activeAlerts?.lastRetrieved && (
            <Grid item xs={12}>
              <Typography variant="body1" color="secondary">
                {`Last updated ${formatDistance(
                  new Date(currentForecast?.activeAlerts?.lastRetrieved!),
                  new Date(),
                  {
                    addSuffix: true
                  }
                )}`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ my: 1 }}>
          <Divider variant="fullWidth" />
        </Box>
        {currentForecast?.activeAlerts?.alerts?.length &&
          currentForecast.activeAlerts.alerts.map((x) => {
            return (
              <Box key={x.event}>
                <Grid container>
                  <Grid item>
                    <Typography variant="h6">{`${x.headline}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="secondary" variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                      {x.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ my: 1 }}>
                  <Divider variant="fullWidth" />
                </Box>
              </Box>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertsModal;
