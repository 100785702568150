import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  Grid,
  Box,
  Divider,
  Stack
} from '@mui/material';
import { formatDistance } from 'date-fns';
import { ApplicationContext } from '../App';
import {
  getTemperatureValue,
  getTemperatureUnitDisplay,
  getWindDirection
} from '../helpers/unitConverter';
import { GridPointPeriod } from '../models/FreeForecast';

export interface CurrentObsModalModalProps {
  open: boolean;
  handleClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CurrentObsModal = (props: CurrentObsModalModalProps) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('md'));
  const { currentForecast } = useContext(ApplicationContext);
  const style = {
    p: 4,
    '& .MuiDialog-container': {
      alignItems: small ? 'flex-start' : 'center'
    }
  };

  const getWindDisplay = (
    direction?: number | null,
    speed?: number | null,
    gust?: number | null
  ) => {
    if (direction === 0 || speed === 0) return 'Calm';

    const windDirection = direction ? `${getWindDirection(direction)} ` : '';
    const windSpeed = speed ? `${Math.round(speed * 0.621371)} mph ` : '';
    const windGust = gust ? `gusting to ${Math.round(gust * 0.621371)}` : '';

    return `${windDirection}${windSpeed}${windGust}`;
  };

  const getLabelValueDisplay = (label: string, value: string) => {
    return (
      <Grid container alignItems="normal" spacing={6}>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Typography variant="h6" color="text.primary">
              {label}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" color="text.primary">
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog fullScreen={small} open={props.open} onClose={props.handleClose} sx={style}>
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h4">Current Weather</Typography>
          </Grid>
          {currentForecast?.currentWeather?.station && (
            <Grid item xs={12}>
              <Typography variant="body1" color="secondary">
                {currentForecast?.currentWeather?.station}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h3" color="text.primary" align="center">
          {`${
            currentForecast?.currentWeather?.temperature?.value ||
            currentForecast?.currentWeather?.temperature?.value === 0
              ? getTemperatureValue(currentForecast?.currentWeather?.temperature?.value)
              : '--'
          }${getTemperatureUnitDisplay()}`}
        </Typography>
        {currentForecast?.currentWeather?.timestamp && (
          <Typography variant="body2" color="secondary" align="center">
            {`Last updated ${formatDistance(
              new Date(currentForecast?.currentWeather?.timestamp!),
              new Date(),
              {
                addSuffix: true
              }
            )}`}
          </Typography>
        )}
        <Box sx={{ my: 1 }}>
          <Divider variant="middle" />
        </Box>
        {getLabelValueDisplay(
          'Humidity',
          `${
            currentForecast?.currentWeather?.relativeHumidity?.value
              ? Math.round(currentForecast.currentWeather.relativeHumidity.value)
              : '--'
          }%`
        )}
        {getLabelValueDisplay(
          'Wind',
          getWindDisplay(
            currentForecast?.currentWeather?.windDirection?.value,
            currentForecast?.currentWeather?.windSpeed?.value,
            currentForecast?.currentWeather?.windGust?.value
          )
        )}
        {getLabelValueDisplay(
          'Pressure',
          `${
            currentForecast?.currentWeather?.barometricPressure?.value
              ? Math.round(currentForecast?.currentWeather?.barometricPressure?.value / 10) / 10
              : '--'
          } mb`
        )}
        {getLabelValueDisplay(
          'Dewpoint',
          `${
            currentForecast?.currentWeather?.dewpoint?.value ||
            currentForecast?.currentWeather?.dewpoint?.value === 0
              ? getTemperatureValue(currentForecast?.currentWeather?.dewpoint?.value)
              : '--'
          }${getTemperatureUnitDisplay()}`
        )}
        {(currentForecast?.currentWeather?.windChill?.value ||
          currentForecast?.currentWeather?.windChill?.value === 0) &&
          (currentForecast?.currentWeather?.temperature?.value ||
            currentForecast?.currentWeather?.temperature?.value === 0) &&
          currentForecast?.currentWeather?.windChill?.value <
            currentForecast?.currentWeather?.temperature?.value &&
          getLabelValueDisplay(
            'Wind Chill',
            `${
              currentForecast?.currentWeather?.windChill?.value
                ? getTemperatureValue(currentForecast?.currentWeather?.windChill?.value)
                : '--'
            }${getTemperatureUnitDisplay()}`
          )}
        {(currentForecast?.currentWeather?.heatIndex?.value ||
          currentForecast?.currentWeather?.heatIndex?.value === 0) &&
          getLabelValueDisplay(
            'Heat Index',
            `${
              currentForecast?.currentWeather?.heatIndex?.value
                ? getTemperatureValue(currentForecast?.currentWeather?.heatIndex?.value)
                : '--'
            }${getTemperatureUnitDisplay()}`
          )}
        <Box sx={{ my: 1 }}>
          <Divider variant="fullWidth" />
        </Box>
        {currentForecast?.dailyForecast?.periods?.length &&
          currentForecast.dailyForecast.periods.map((x, index: number) => {
            const previous = currentForecast?.dailyForecast?.periods[index - 1];
            const next = currentForecast?.dailyForecast?.periods[index + 1];
            const getTempDisplay = (x: GridPointPeriod) => {
              return `${x.temperature}${getTemperatureUnitDisplay()}`;
            };
            return (
              <Box key={x.number}>
                <Grid container>
                  <Grid item>
                    <Stack direction="row" spacing={2}>
                      <Typography variant="body1" fontWeight="bold" fontStyle="oblique">
                        {x.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        color={x.isDaytime ? 'error.light' : 'info.light'}>
                        {`${getTempDisplay(x)}`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="secondary" variant="body2">
                      {x.detailedForecast}
                    </Typography>
                  </Grid>
                </Grid>
                {(next && previous?.isDaytime) || (index === 0 && !x.isDaytime) ? (
                  <Box sx={{ my: 1 }}>
                    <Divider variant="fullWidth" />
                  </Box>
                ) : null}
              </Box>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CurrentObsModal;
