import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import LocationDisabled from '@mui/icons-material/LocationDisabled';
import LocationOn from '@mui/icons-material/LocationOn';
import { Switch, Box } from '@mui/material';
import { ApplicationContext } from '../App';

export const FollowMeToggle = () => {
  const { toggleFollowMe, followMe } = React.useContext(ApplicationContext);
  return (
    <Box>
      <Switch sx={{ mx: 0 }} checked={followMe} onClick={toggleFollowMe} />
      <IconButton sx={{ ml: 1 }} onClick={toggleFollowMe} color="inherit">
        {!followMe ? <LocationDisabled /> : <LocationOn />}
      </IconButton>
      {followMe ? 'Follow My Location' : 'Device Location Disabled'}
    </Box>
  );
};

export default FollowMeToggle;
