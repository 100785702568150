import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const NewLine = () => (
    <>
      <br />
      <br />
    </>
  );

  return (
    <Box sx={{ mt: 6, p: 3 }}>
      <>
        <IconButton
          sx={{ pl: 0 }}
          onClick={() => navigate('/settings')}
          color="primary"
          size="small"
          disableRipple>
          <ChevronLeft /> Back
        </IconButton>
      </>
      <Typography variant="h4">
        WxYapper Privacy Policy <NewLine />
      </Typography>
      <Typography>
        Effective Date: January 24, 2023
        <NewLine />
      </Typography>
      <Typography sx={{ whiteSpace: 'pre-wrap' }}>
        Welcome to WxYapper. This is our Privacy Policy (“Privacy Policy”), which we have created to
        inform you of the types of information that we collect from you. We have also created this
        Privacy Policy to inform you of how we use that information because we believe in
        transparency and value your privacy.
        <NewLine />
        We may change or discontinue this Privacy Policy at any time, and we reserve the right to do
        so. If we change this Privacy Policy, we will also change the Effective Date, which is
        located above. If you continue to use the Website or App after we change the Effective Date,
        you agree to our change to the Privacy Policy.
        <NewLine />
        What do you collect from me?
        <NewLine />
        We may collect the following personal information from you:
        <NewLine />
        1. Any information that you voluntarily submit to the Website, including, but not limited
        to, in response to questions posed by the Website;
        <br />
        2. Your user name, name, email address, date of birth, gender, phone number, state, city,
        country, or zip code;
        <br />
        3. Your payment information, which is used to complete a transaction; and;
        <br />
        4. Links to your social networking profile.
        <NewLine />
        We may also collect the following personally identifiable information from you:
        <NewLine />
        1. Your IP address;
        <br />
        2. Your geolocation;
        <br />
        3. Your use of the Website;
        <br />
        4. Information stored in cookies, pixel tags, or web beacons; and
        <br />
        5. Any other additional analytics data that you voluntarily submit to the Website.
        <NewLine />
        How do you use this information? We use this personal and personally identifiable
        information to:
        <NewLine />
        1. Provide you with the Website and its services;
        <br />
        2. To communicate with you;
        <br />
        3. To troubleshoot problems with the Website;
        <br />
        4. To complete a transaction that you have initiated;
        <br />
        5. To update you on changes to the Website; and
        <br />
        6. To update you on our services or the services of third parties.
        <NewLine />
        How do you store this information?
        <NewLine />
        WxYapper stores and processes your personal and personally identifiable information on
        computers located within the United States. We use commercially standard technology to help
        protect against the unauthorized disclosure of your personal and personally identifiable
        information, including encryption. Though we do our best to protect your personal and
        personally identifiable information, you provide all such information at your own risk.
        <NewLine />
        Do you share my information with third parties?
        <NewLine />
        WxYapper will only share your personal or personally identifiable information with third
        parties in the following situations:
        <NewLine />
        1. Where WxYapper has obtained your consent;
        <br />
        2. Where it is necessary to provide you with services initiated at your request;
        <br />
        3. Where it is necessary to share such information with WxYapper’s parents, subsidiaries,
        successors, and assigns;
        <br />
        4. Where WxYapper has been purchased by or has purchased a third party;
        <br />
        5. Where it is needed to respond to information requests by government authorities;
        <br />
        6. Where it is demanded by a court order or subpoena;
        <br />
        7. Where it is needed to protect the employees, independent contractors, users, members,
        officers, directors, or shareholders of WxYapper; and
        <br />
        8. Where it is needed to help prevent against fraud or the violation of any applicable law,
        statute, regulation, ordinance, or treaty.
        <NewLine />
        Any personal or personally identifiable information that you include within your Website
        profile or elsewhere on the Website may also be displayed to third parties. You are advised
        to review that information before you submit it to the Website.
        <NewLine />
        What can I do to stop the collection of my personal or personally identifiable information?
        <NewLine />
        You can cease WxYapper’s collection of your personal and personally identifiable information
        by taking the following steps:
        <NewLine />
        1. By adjusting your web browser settings to block or limit cookies, pixel tags, and web
        beacons;
        <br />
        2. By deleting your Account in accordance with the Terms of Use Agreement.
        <NewLine />
        What are my duties when using the Website?
        <NewLine />
        You have the duty to:
        <NewLine />
        1. Inform WxYapper of any changes to your personal or personally identifiable information;
        and
        <br />
        2. Protect the security of your username, password, and personal or personally identifiable
        information.
        <NewLine />
        Your California Privacy Rights
        <NewLine />
        California residents have the right to receive information that identifies any third-party
        companies or individuals that WxYapper has shared your personal or personally identifiable
        information with in the previous calendar year, as well as a description of the categories
        of personal or personally identifiable information disclosed to that third party. You may
        obtain this information once a year and free of charge by contacting Cardinalis Web
        Development at support@cardinaliswebdevelopment.com
        <NewLine />
        How can I contact you if I have questions?
        <NewLine />
        All questions and concerns regarding this Privacy Policy may be directed to Cardinalis Web
        Development at support@cardinaliswebdevelopment.com.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
